<template>
  <section class="intro-x">
    <div class="box p-4 my-5">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div class="flex items-center">
          <label for="dateStart" class="w-1/3 capitalize">Fecha <br> inicio</label>
          <input
          v-model="filterModel.dateStart"
          id="dateStart"
          type="date"
          class="form-control"
          >
        </div>
        <div class="flex items-center">
          <label for="" class="w-1/3 capitalize">Fecha <br> fin</label>
          <input
          v-model="filterModel.dateFinish"
          type="date"
          class="form-control"
          >
        </div>
        <!-- <div class="flex items-center">
          <label for="" class="w-1/3 capitalize">Estado</label>
          <select class="form-control">
            <option value="">--Seleccionar--</option>
          </select>
        </div> -->
        <!-- <div class="mb-1 flex items-center">
          <label for="branchOfficeId" class="w-1/3">Ub. Despacho</label>
          <select name="branchOfficeId"
          v-model="filterModel.branchOfficeId"
          class="form-control capitalize"
          >
            <option :value="null">Seleccionar</option>
            <option
            v-for="(row,k) in getBranchsOffice"
            :key="k"
            :value="row.id"
            class="capitalize"
            >
              {{row.name}}
            </option>
          </select>
        </div> -->
        <!-- cargo de quien solicita -->
        <!-- <div class="mb-1 flex items-center">
          <label for="areaId" class="w-1/3">Area Solicita</label>
          <select name="areaId"
          v-model="filterModel.areaId"
          class="form-control capitalize"
          >
            <option :value="null">Seleccionar</option>
            <option
            v-for="(row,k) in getAreas"
            :key="k"
            :value="row.id"
            class="capitalize"
            >
              {{row.name}}
            </option>
          </select>
        </div> -->
      </div>
      <div class="w-full mt-4">
        <button
        type="button"
        class="btn btn-primary w-full lg:w-auto"
        @click="onFilter"
        >
          Filtrar
        </button>
        <button
        type="button"
        class="btn btn-warning w-full lg:w-auto ml-2"
        @click="onFilterClear"
        >
          Limpiar
        </button>
      </div>
    </div>

    <div v-if="$can('pharmasan.administrativa.compras-administrativas.consolidados.access')" class="my-5 box p-4">
      <button
      type="button"
      class="btn btn-primary"
      @click="onSubmit"
      >
        Crear pedido
      </button>
    </div>
    <div class="w-full my-5 p-datatable-sm overflow-x-auto">
      <DataTable
      :value="getItems"
      class="p-datatable-sm"
      >
        <Column header="" headerStyle="width:70px;" >
          <template #body="{ index }">
            <button
            class="btn btn-danger p-1"
            title="Remover"
            @click="onItemRemove(index)"
            >
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
              </svg>
            </button>
          </template>
        </Column>
        <Column
        header="Articulo"
        field="articleName"
        headerStyle="width:240px;"
        sortable
        />

        <Column header="Und. Medida" field="unitName" sortable headerStyle="width:140px;" />
        <Column header="Exist. Inv." field="inventoryStock" headerStyle="width: 140px;" />
        <Column
        header="Min. Exist. Prod"
        field="articleMimunStock"
        sortable
        headerStyle="width:140px;"
        >
          <template #body="{ data }">
            <span>{{$h.formatNumber(data.articleMimunStock)}}</span>
          </template>
        </Column>
        <!-- <Column header="Presentacion" field="presentationName" headerStyle="width:140px;" /> -->
        <!-- <Column header="Cant. Requerida" headerStyle="width:140px;" field="requiredAmountByBranch" /> -->
        <Column
        header="Cant. Sugerida"
        field="requiredAmount"
        headerStyle="width:110px;"
        />
        <Column
        header="Cantidad"
        headerStyle="width:110px;"
        >
          <template #body="{ data }">
            <input
            :value="data.amountApproved"
            type="number"
            class="form-control"
            min="0"
            @change="data.amountApproved = +$event.target.value"
            >
          </template>
        </Column>
        <Column
        header="Proveedor"
        headerStyle="width:240px;"
        >
          <template #body="{ index, data }">
            <select
            class="form-control"
            :value="data.supplierId"
            @change="onSupplierChange($event,index, data)"
            >
              <option
              v-for="(row,k) in data.suppliers"
              :key="k"
              :value="row.supplierId"
              >
                {{row.supplier.supplierName}}
              </option>
            </select>
          </template>
        </Column>
        <Column
        header="Vlr. unitario"
        headerStyle="width:140px;"
        >
          <template #body="{data}">
            <input
            :value="data.supplierPrice"
            type="number"
            class="form-control"
            min="0"
            step="500"
            @change="data.supplierPrice = +$event.target.value"
            >
          </template>
        </Column>
        <Column
        header="Vlr. Total"
        headerStyle="width:140px;"
        >
          <template #body="{ data }">
            <span>{{$h.formatCurrency(data.supplierPrice * data.amountApproved)}}</span>
          </template>
        </Column>

        <template #footer>
          Total de {{getItems ? getItems.length : 0 }} Articulos.
        </template>
      </DataTable>
    </div>

    <div class="w-full box p-4">
      <div class="flex flex-col lg:flex-row items-center">
        <div class="w-full lg:w-2/3 text-right lg:border-r lg:pr-2">
          <span class=" text-lg font-bold">Total</span>
        </div>
        <div class="w-full lg:w-1/3 text-right">
          <span class="text-lg">{{$h.formatCurrency(getTotal)}} </span>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { computed, onMounted, ref } from 'vue'
import consolidateByService from '../../../../services/consolidateByService'
import consolidarSolicitudService from '../../../../services/consolidarSolicitudService'
import { messageConfirm, messageSuccess, messageWarning } from '../../../../../../../libs/mensajes'
// import brancheOfficesListar from '../../../../services/brancheOfficesListar'
import areasListar from '../../../../services/areasListar'

export default {
  name: 'Consolidados',
  components: {
    DataTable,
    Column
  },
  setup () {
    const filterModel = ref({
      dateStart: null,
      dateFinish: null,
      branchOfficeId: null,
      areaId: null
    })
    const items = ref([])
    const getItems = computed(() => {
      return items.value
    })

    const setItems = (value) => {
      items.value = value
    }
    // const optiosBranchOffice = ref({
    //   data: []
    // })
    // const getBranchsOffice = computed(() => {
    //   return optiosBranchOffice.value.data
    // })
    const optionsAreas = ref({
      data: []
    })
    const getAreas = computed(() => {
      return optionsAreas.value.data
    })
    const onFilter = () => {
      const payload = { ...filterModel.value }
      consolidateByService(payload).then(({ data }) => {
        const { rows } = data
        if (rows.length) return setItems(rows)
        messageWarning('No se encontraron resultados')
        setItems([])
      }).catch(err => {
        console.error(err.message)
      })
    }

    const onItemRemove = (value) => {
      items.value.splice(value, 1)
    }

    const getTotal = computed(() => {
      const total = items.value.reduce((total, current) => (total += (current.amountApproved * current.supplierPrice)), 0)
      return total
    })

    const onSubmit = () => {
      if (items.value.length === 0) {
        return messageWarning('No es posible continuar.')
      }
      if (items.value.some(a => a.supplierId === null)) {
        return messageWarning('Uno de los articulos no tiene un proveedor seleccionado. remover antes de continuar')
      }
      messageConfirm().then(({ isConfirmed }) => {
        if (isConfirmed) {
          const payload = { articles: items.value }
          consolidarSolicitudService(payload).then(result => {
            messageSuccess('Los pedidos han sido creados')
            items.value = []
          }).catch(err => {
            console.error(err.message)
          })
        }
      })
    }
    const onSupplierChange = (evt, index, value) => {
      const cardcode = evt.target.value
      const key = value.suppliers.findIndex(a => a.supplierId === cardcode)
      if (key >= 0) {
        const { price } = value.suppliers[key]
        items.value[index].supplierPrice = price
        items.value[index].supplierId = cardcode
      }
    }
    // const fetchBranchOffices = () => {
    //   return brancheOfficesListar().then(({ data }) => {
    //     optiosBranchOffice.value.data = data
    //     return data
    //   }).catch(err => {
    //     console.error(err.message)
    //   })
    // }
    const fetchAreas = () => {
      return areasListar().then(({ data }) => {
        optionsAreas.value.data = data
        return data
      }).catch(err => {
        console.error(err.message)
      })
    }
    const onFilterClear = () => {
      filterModel.value.dateStart = null
      filterModel.value.dateFinish = null
      filterModel.value.branchOfficeId = null
      filterModel.value.areaId = null
    }
    onMounted(() => {
      // fetchBranchOffices()
      fetchAreas()
    })
    return {
      filterModel,
      getItems,
      getTotal,
      onFilter,
      onItemRemove,
      onSubmit,
      onSupplierChange,
      onFilterClear,
      // getBranchsOffice,
      getAreas
    }
  }
}
</script>
